import {
  type ApiClientBase,
  type ApiFetchOptions,
  DefaultApiFetchOptions
} from "~/stNuxtCommon/composables/api/useApiClientBase";
import type {ApiTopDataProps, ApiTopPhotoProps,} from "~/projectCommon/frontend/model/ApiTopDataProps";
import type {ApiYouTubeVideoSimpleProps} from "~/projectCommon/frontend/model/ApiYouTubeVideoProps";
import type {ApiPostSimpleProps} from "~/projectCommon/frontend/model/ApiPostProps";
import type {ApiJournalSimpleProps} from "~/projectCommon/frontend/model/ApiJournalProps";
import type {ApiPostSeriesProps} from "~/projectCommon/frontend/model/ApiPostSeriesProps";

export function createTopApiClient(client: ApiClientBase, options: FrontendClientOptions, fetchOptions?: ApiFetchOptions) {
  const rootDir = "/top"
  const lang = options.lang
  const fetchOptions2 = fetchOptions || DefaultApiFetchOptions
  return {
    getData: async (): Promise<ApiTopDataProps> => {
      let params = {
        lang: lang,
      }
      const { data } = await client.get(`${rootDir}/data`, params, fetchOptions2)
      return {
        videos: data.videos as ApiYouTubeVideoSimpleProps[],
        journals: data.journals as ApiJournalSimpleProps[],
        photos: data.photos as ApiTopPhotoProps[],
        posts: data.posts as ApiPostSimpleProps[],
        postSeriesList: data.postSeriesList as ApiPostSeriesProps[],
      }
    }
  }
}
