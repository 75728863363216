import {
  type ApiClientBase,
  type ApiFetchOptions,
  DefaultApiFetchOptions
} from "~/stNuxtCommon/composables/api/useApiClientBase";
import type {ApiPostProps, ApiPostSimpleProps} from "~/projectCommon/frontend/model/ApiPostProps";
import type {ApiPostSeriesProps} from "~/projectCommon/frontend/model/ApiPostSeriesProps";

export function createPostApiClient(client: ApiClientBase, options: FrontendClientOptions, fetchOptions?: ApiFetchOptions) {
  const rootDir = "/posts"
  const lang = options.lang
  const fetchOptions2 = fetchOptions || DefaultApiFetchOptions
  return {
    getList: async (params: Record<string, any>) => {
      let params2: any = params || {}
      params2.lang = lang
      const {data} = await client.get(`${rootDir}`, params2, fetchOptions2)
      return {
        posts: data.posts as ApiPostSimpleProps[],
        totalCount: data.totalCount as number
      }
    },
    getById: async (id: string) => {
      let params = {
        lang: lang
      }
      const {data} = await client.get(`${rootDir}/${id}`, params, fetchOptions2)
      return {
        post: data.post as ApiPostProps,
        postSeries: data.postSeries as ApiPostSeriesProps | null,
      }
    }
  }
}
