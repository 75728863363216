import {type ApiClientBase, type ApiFetchOptions, DefaultApiFetchOptions} from "~/stNuxtCommon/composables/api/useApiClientBase";
import type {ApiAreaMapProps} from "~/projectCommon/frontend/model/ApiAreaMapProps";
import type {ApiPlaceProps} from "~/projectCommon/frontend/model/ApiPlaceProps";

interface GetMapsParams {
  page: number
  pageSize: number
  lang?: string
}

interface GetMapsResponse {
  areaMaps: ApiAreaMapProps[]
  page: number
  totalCount: number
}

interface GetMapParams {
  lang?: string
  placeKindIdName?: string
}

interface GetMapResponse {
  areaMap: ApiAreaMapProps
  places: ApiPlaceProps[]
}

export function createAreaMapApiClient(client: ApiClientBase, options: FrontendClientOptions, fetchOptions?: ApiFetchOptions) {
  const rootDir = "/areaMaps"
  const lang = options.lang
  const fetchOptions2 = fetchOptions || DefaultApiFetchOptions
  return {
    getList: async (params: GetMapsParams | null = null): Promise<GetMapsResponse> => {
      params ||= {
        page: 1,
        pageSize: 1000,
      }
      params.lang ||= lang
      const { data } = await client.get(`${rootDir}/`, params, fetchOptions2)
      return {
        areaMaps: data.areaMaps as ApiAreaMapProps[],
        page: data.page as number,
        totalCount: data.totalCount as number,
      }
    },
    getById: async (id: string, params: GetMapParams | null = null): Promise<GetMapResponse> => {
      params ||= {}
      params.lang ||= lang
      const { data } = await client.get(`${rootDir}/${id}`, params, fetchOptions2)
      return {
        areaMap: data.areaMaps as ApiAreaMapProps,
        places: data.places as ApiPlaceProps[]
      }
    }
  }
}
