import {
  type ApiClientBase,
  type ApiFetchOptions,
  DefaultApiFetchOptions
} from "~/stNuxtCommon/composables/api/useApiClientBase";
import type {ApiPostSeriesProps} from "~/projectCommon/frontend/model/ApiPostSeriesProps";

export function createPostSeriesApiClient(client: ApiClientBase, options: FrontendClientOptions, fetchOptions?: ApiFetchOptions) {
  const rootDir = "/postSeries"
  const lang = options.lang
  const fetchOptions2 = fetchOptions || DefaultApiFetchOptions
  return {
    getList: async (params: Record<string, any>) => {
      let params2: any = params || {}
      params2.lang = lang
      const {data} = await client.get(`${rootDir}`, params2, fetchOptions2)
      return {
        postSeriesList: data.postSeriesList as ApiPostSeriesProps[],
        totalCount: data.totalCount as number
      }
    },
    getById: async (id: string) => {
      let params = {
        lang: lang
      }
      const {data} = await client.get(`${rootDir}/${id}`, params, fetchOptions2)
      return {
        postSeries: data.postSeries as ApiPostSeriesProps
      }
    }
  }
}
