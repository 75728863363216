import {
  type ApiClientAsyncData,
  type ApiClientBase,
  type ApiFetchOptions,
  DefaultApiFetchOptions
} from "~/stNuxtCommon/composables/api/useApiClientBase";

interface GetListParams {
  page: number
  pageSize: number
}

interface PhotoClientInterface {
  getList(params?: GetListParams): Promise<ApiClientAsyncData>
  getById(id: string): Promise<ApiClientAsyncData>
}

export function createPhotoApiClient(client: ApiClientBase, options: FrontendClientOptions, fetchOptions?: ApiFetchOptions): PhotoClientInterface {
  const rootDir = "/photos"
  const lang = options.lang
  const fetchOptions2 = fetchOptions || DefaultApiFetchOptions
  return {
    getList: async (params?: GetListParams) => {
      let params2: any = params || {}
      params2.lang = lang
      return await client.get(`${rootDir}/`, params2, fetchOptions2)
    },
    getById: async (id: string) => {
      return await client.get(`${rootDir}/${id}`, {
        lang: lang
      }, fetchOptions2)
    }
  }
}
