import {type ApiClientBase, type ApiFetchOptions, DefaultApiFetchOptions} from "~/stNuxtCommon/composables/api/useApiClientBase";
import type {ApiPlaceProps, ApiPlaceSimpleProps} from "~/projectCommon/frontend/model/ApiPlaceProps";
import type {ApiAreaMapProps} from "~/projectCommon/frontend/model/ApiAreaMapProps";
import type {ApiPlacePostProps} from "~/projectCommon/frontend/model/ApiPlacePostProps";

interface GetListParams {
  areaMapId: string
  page: number
  pageSize: number
}

interface GetListResponse {
  areaMap: ApiAreaMapProps
  places: ApiPlaceSimpleProps[]
  totalCount: number
}

interface GetByIdResponse {
  place: ApiPlaceProps
  placePosts: ApiPlacePostProps[]
}

interface GetPostByIdResponse {
  placePost: ApiPlacePostProps
}

export function createPlaceApiClient(client: ApiClientBase, options: FrontendClientOptions, fetchOptions?: ApiFetchOptions) {
  const rootDir = "/places"
  const lang = options.lang
  const fetchOptions2 = fetchOptions || DefaultApiFetchOptions
  return {
    getList: async (params: GetListParams): Promise<GetListResponse> => {
      let params2: Record<string, any> = params || {}
      params2.lang = lang
      const {data} = await client.get(`${rootDir}/`, params2, fetchOptions2)
      return {
        areaMap: data.areaMap as ApiAreaMapProps,
        places: data.places as ApiPlaceSimpleProps[],
        totalCount: data.totalCount,
      }
    },
    getById: async (id: string): Promise<GetByIdResponse> => {
      let params = {
        lang: lang
      }
      const {data} = await client.get(`${rootDir}/${id}`, params, fetchOptions2)
      return {
        place: data.place as ApiPlaceProps,
        placePosts: data.placePosts as ApiPlacePostProps[],
      }
    },
    getPostById: async (placeId: string, postId: string): Promise<GetPostByIdResponse> => {
      let params = {
        lang: lang
      }
      const {data} = await client.get(`${rootDir}/${placeId}/posts/${postId}`, params, fetchOptions2)
      return {
        placePost: data.placePost as ApiPlacePostProps,
      }
    }
  }
}
